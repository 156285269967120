
      .products-section{
        margin-top:-15%;
        padding: 10%;
    }
    .products-section h2{
        color: #c21b1b;
      
        
    }
    .products-section .subheading{
        color: #181717;
        font-size: 16px;
        font-weight: 600;
    }
    .products-section .row
    {
        margin-top: 10%;
    }
    .product-img
    {
        text-align: center;
    }
    .product-img img{
        width: 50%;
    }

    .container-omega {
      
        width: 90%;
        margin: 0 auto;
        padding: 0px auto;
      }
      .container-feature{
        background: #adb5bd;
      }
      .omega-background{
        background:#FFF;
        border-radius: 20px;
        cursor: pointer;
        /* margin: 10px 0px;
        padding: 5px 0px; */
        margin: 20px 20px;
        padding: 20px 0px;
      }
      