.link {
    margin: 12px 10px ;
    padding: 0px 0px;
    font-size: 16px;
    text-decoration: none;
    color: #737579 !important;
  } 
.link:last-child{
    margin: 12px 0px;
    margin-left: 10px;
}
  .product-link{
    text-decoration: none;
    color: #000 ;
    margin: 5px 10px;
  }
 

