.container-contact {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
.contact-form{
   
    background: #fff;
    margin-top: 5%;
    width: 80%;
}
.contact-form .form-control{
    border-radius:1rem;
}
.contact-image{
    text-align: center;
  
}
.contact-image img{
    border-radius: 50%;
    width: 10%;
    margin-top: -5%;
    padding:2px;
    border: 2px solid #fff;
}
.contact-form form{
    padding-top: 10px;
    padding-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
}

.contact-form p{
    text-align: center;
}
.contact-form .btnContact {
    text-align: center;
    width: 60%;
    border-radius: 1rem;
    padding: 10px;
    background: #dc3545;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}
.area-control{
    width: 100%; height: 150px;
}
.form-group{
    margin-bottom: 10px;
}

