.container-subscribe{
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
.newsletter {
    background: #f0eae6;
    padding: 50px 0px;
    }    
.newsletter .content h2 {
    color: #ae1d15;
    margin-bottom: 40px; }
    