   .container-counter {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
  .number {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .number .counter {
    font-weight: bold;
    font-size: 24px;
  }
  .number span {
    display: block;
  }
  .counter {
    background:#FFF;
    border-radius: 20px;
    cursor: pointer;
    margin: 15px 0;
    padding: 20px 2px;
}
.count-text {
    font-size: 16px;
    font-weight: bold;
    margin:auto;
}
.fa-2x {
    color: #cd2522;
}