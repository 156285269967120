.container-client {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }

.client_logo{
    height: 80px;
    width: 90px;

}
 .client-background {
        background: #FFF;
        border-radius: 10px; 
        cursor: pointer;
        margin: 20px 0px;
        padding: 20px 25px;
        transition: all 0.7s ease-out;
        height: 120px;
        width: 135px;
    }
    
.client-background:hover {
        box-shadow: 0 0 10px rgb(0, 0, 0);
     }    
