.container-value {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
.value_img{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.value_img img{
    object-fit: cover; 
}
.text_style{
    text-align: justify; 
}