.container-header {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
.text-area{
    margin: auto;
}
.text-area strong{
    font-weight: 700;
}
.header-text{
    font-size:16px;
}
.text-area p{
    font-weight: 600;
    font-size: 16px;
}
.button-area{
    margin: auto;
   margin-top: 5px;
   padding-right: 40px;
   padding-left: 50px;
}
.button-area p{
   font-size: 12px;
}
