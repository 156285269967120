.container-about {
    width: 90%;
    margin: 0 auto;
    padding: 0px auto;
 }
.how-section1{
    margin-top:-10%;
    padding: 80px 0px;
}
.how-section1 h2{
    color: #c21b1b;
}
.how-section1 .subheading{
    color: #181717;
    font-size: 16px;
    font-weight: 600;
}
.how-section1 .row{
    margin-top: 10%;
}
.how-img {
    text-align: center;
}
.how-img img{
    border-radius: 5px;
    height: 100%;
    width: 100%;
}
.text_style{
    text-align: justify; 
}