.container-pricing {
  width: 90%;
  margin: 0 auto;
  padding: 0px auto;
}
.card-pricing{
  /* margin: 5px; */
  
}
.card-pricing.popular {
    z-index: 1;
    border: 2px solid rgb(49, 197, 49);
}
.card-pricing .list-unstyled li {
    padding: .5rem 0;
    color: #6c757d;
}