@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
body {
    background: #e2e4e8;
    font-family: 'Poppins', sans-serif;
}

.container-service{
    width: 90% ;
    margin: 0 auto;
    padding: 0px auto;
}
.social-box .box {
    background: #FFF;
    border-radius: 20px; 
    cursor: pointer;
    margin: 15px 0;
    padding: 20px 2px;
    transition: all 0.7s ease-out;
}

.social-box .box:hover {
   box-shadow: 0 0 50px green;
}
.box-title{
    margin-top: 5px;
    font-size:14px;
    color:#DC4C64;
}
.service_icon{
    height: 50px;
    width: 50px;
    
}
